import React from 'react';
import { Link } from 'react-router-dom';
import { TrendingUp, HandshakeIcon, BarChart, CreditCard, CheckCircle } from 'lucide-react';
import '../../css/content.css';

const FeatureCard = ({ icon, title, description }) => (
  <div className="service-card">
    {icon}
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

function ProfitShareOptions() {
  return (
    <div className="profit-share-options-page">
      <div className="hero">
        <h1>Unlock Your Property's Full Potential</h1>
        <p>Maximize your rental income without the hassle - we don't get paid unless you do</p>
        <Link to="/contact" className="cta-button">
          Explore Profit Share Opportunities
        </Link>
      </div>

      <div className="services-grid">
        <FeatureCard
          icon={<TrendingUp size={48} />}
          title="Increased Rental Income"
          description="We identify and implement strategies to boost your property's earning potential."
        />
        <FeatureCard
          icon={<HandshakeIcon size={48} />}
          title="Risk-Free Partnership"
          description="We manage your property for free, establish a base rate to cover your costs, and only when we exceed this do we get paid."
        />
        <FeatureCard
          icon={<BarChart size={48} />}
          title="Expert Management"
          description="Benefit from our market knowledge and management expertise without any additional cost."
        />
        <FeatureCard
          icon={<CreditCard size={48} />}
          title="Guaranteed Base Rent"
          description="We ensure you receive enough to cover your property costs, with potential for much more."
        />
      </div>

      <div className="about-us">
        <h2>How Our Profit Share Model Works</h2>
        <p>
          Our Profit Share Option is designed for landlords who believe their property has untapped potential but lack the time or resources to maximize it. Here's how it works:
        </p>
        <ol>
          <li>We assess your property's current performance and potential for improvement.</li>
          <li>If we agree there's room for growth, we take over management of your property at no cost to you.</li>
          <li>We guarantee you a base rent that covers your property costs (mortgage, etc.).</li>
          <li>Our team implements strategies to increase the property's rental income.</li>
          <li>Any additional profit above your base rent is split 50/50 between you and us.</li>
          <li>You enjoy increased returns without any additional effort or risk.</li>
        </ol>
        <p>
          This innovative model aligns our interests with yours - we only profit when we succeed in increasing your property's income.
        </p>        
      </div>

      

      <div className="cta-section">
        <h2>Ready to Boost Your Rental Income?</h2>
        <p>Contact us today to see how our Profit Share Option can work for your property.</p>
        <Link to="/contact" className="cta-button">
          Get Started Now
        </Link>
      </div>
    </div>
  );
}

export default ProfitShareOptions;