import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'lucide-react';
import '../../css/content.css';

const FAQItem = ({ question, answer, isOpen, toggleOpen }) => (
  <div className="faq-item">
    <button className="faq-question" onClick={toggleOpen}>
      {question}
      {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
    </button>
    {isOpen && <div className="faq-answer">{answer}</div>}
  </div>
);

function FAQ() {
  const [openIndex, setOpenIndex] = React.useState(null);

  const toggleOpen = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is the Rent to Buy scheme?",
      answer: "Our Rent to Buy scheme is designed to help aspiring homeowners step onto the property ladder. You choose a property, pay rent, and gradually build equity through an option fee. After an agreed period (typically 3-5 years), you have the option to purchase the property where we credit your accululated option fee to your deposit."
    },
    {
      question: "How does the Guaranteed Rent service work?",
      answer: "With our Guaranteed Rent service, we lease your property for a fixed term of 3-5 years and guarantee your rent for the entire period, paid monthly in advance. We handle all aspects of property management, including finding tenants and maintenance, providing you with a stable, hassle-free income stream."
    },
    {
      question: "What are the benefits of your Profit Share Options for landlords?",
      answer: "Our Profit Share Option allows landlords to potentially increase their rental income without any additional cost or risk. We manage your property for free, guarantee a base rent to cover your costs, and split any additional profit 50/50. This way, you benefit from our expertise in maximizing your property's earning potential."
    },
    {
      question: "How can agents benefit from partnering with Acre Sulis?",
      answer: "Agents can earn double commissions by partnering with us. You can earn fees for bringing us tenants, landlords, or vendors. Even if you have to turn away a client, you can refer them to us and still earn a commission if we're able to help them."
    },
    {
      question: "What solutions do you offer for property sellers?",
      answer: "We offer various solutions for sellers, including bespoke selling strategies, direct property purchase with our unique Above Market Offers, equity release options, and assisted sales where we help you sell for a higher price. We guarantee to make an offer on any property we view."
    },
    {
      question: "Do you offer property management services?",
      answer: "Yes, we provide comprehensive property management services at competitive rates. Our services include tenant sourcing and vetting, rent collection, property maintenance, and regular inspections. We specialize in lettings, ensuring your rental property receives expert attention."
    },
    {
      question: "What types of properties do you have available for rent?",
      answer: "We offer a diverse range of rental properties, from cozy rooms and apartments to spacious family homes. Our properties are available in prime locations and cater to various budgets and preferences. We also offer flexible tenancy options to suit your needs."
    }
  ];

  return (
    <div className="faq-page">
      <div className="hero">
        <h1>Frequently Asked Questions</h1>
        <p>Find answers to common questions about our services and property solutions</p>
      </div>

      <div className="faq-container">
        {faqData.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={openIndex === index}
            toggleOpen={() => toggleOpen(index)}
          />
        ))}
      </div>

      <div className="cta-section">
        <h2>Still have questions?</h2>
        <p>Our team is here to help you with any additional inquiries you may have.</p>
        <Link to="/contact" className="cta-button">
          Contact Us
        </Link>
      </div>
    </div>
  );
}

export default FAQ;