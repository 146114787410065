import React from 'react';
import { Link } from 'react-router-dom';
import { Users, Building, ArrowRight, CheckCircle, Home, Users2 } from 'lucide-react';
import '../../css/content.css';

const FeatureCard = ({ icon, title, description }) => (
  <div className="service-card">
    {icon}
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

const Agents = () => {
  return (
    <div className="agents-page">
      <div className="hero">
        <h1>Partner with Us and Maximize Your Earnings</h1>
        <p>
          As an agent, you have the unique opportunity to earn twice on each deal.
          Bring us tenants or landlords and watch your commissions multiply!
        </p>
      </div>

      <div className="services-grid">
        <FeatureCard
          icon={<Users size={48} />}
          title="Bring Us Tenants"
          description="Earn a finding fee when you connect us with quality tenants. It's that simple!"
        />
        <FeatureCard
          icon={<Building size={48} />}
          title="Bring Us Landlords"
          description="Introduce landlords open to corporate lease agreements and earn from both sides of the deal."
        />
        <FeatureCard
          icon={<Home size={48} />}
          title="Bring Us Vendors"
          description="Introduce vendors open to creative deals and get a commission from us as well."
        />
        <FeatureCard
          icon={<Users2 size={48} />}
          title="Help Us Help Them"
          description="Bring us vendors or landlords who are struggling and who you have to turn away, allow us to help them. You can still get a commission from us!"
        />
      </div>

      

      <div className="about-us">
        <h2>Double Your Income</h2>
        <p>
          By partnering with us, you're not just making a sale - you're opening up a new revenue stream.
          Get paid by the landlords and vendors for finding us, and by us for bringing a valuable properties to our portfolio.
        </p>
        <Link to="/contact" className="cta-button">
          Lets Talk! <ArrowRight className="inline-icon" size={20} />
        </Link>
      </div>

      

      <div className="cta-section">
        <h2>Ready to Boost Your Business?</h2>
        <p>Join our network of successful agents and start maximizing your earnings today.</p>
        <Link to="/contact" className="cta-button">
          Get Started
        </Link>
      </div>
    </div>
  );
};

export default Agents;