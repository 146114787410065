import React from 'react';
import { Link } from 'react-router-dom';
import { Bed, MapPin, Wifi, Users, CheckCircle } from 'lucide-react';
import '../../css/content.css';

const FeatureCard = ({ icon, title, description }) => (
  <div className="service-card">
    {icon}
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

function RoomsToRent() {
  return (
    <div className="rooms-to-rent-page">
      <div className="hero">
        <h1>Affordable Central Living for Professionals</h1>
        <p>Quality rooms in prime locations, tailored for the modern working individual</p>
        <Link to="/contact" className="cta-button">
          Find Your Perfect Room
        </Link>
      </div>

      <div className="services-grid">
        <FeatureCard
          icon={<MapPin size={48} />}
          title="Prime Locations"
          description="Rooms available in central areas, close to public transport, workplaces, and amenities."
        />
        <FeatureCard
          icon={<Bed size={48} />}
          title="Comfortable Living"
          description="Well-furnished rooms designed for comfort and productivity, perfect for busy professionals."
        />
        <FeatureCard
          icon={<Wifi size={48} />}
          title="All-Inclusive Packages"
          description="Utilities, high-speed internet, and regular cleaning included in your rent for hassle-free living."
        />
        <FeatureCard
          icon={<Users size={48} />}
          title="Professional Community"
          description="Live alongside like-minded professionals in a respectful and dynamic shared living environment."
        />
      </div>

      <div className="about-us">
        <h2>Why Choose Our Rooms?</h2>
        <p>
          At Acre Sulis, we understand the challenges working professionals face 
          when looking for affordable accommodation in central areas. Our Rooms to Rent service 
          is designed to provide you with:
        </p>
        <ul>
          <li>Affordable rent in desirable locations</li>
          <li>Flexible tenancy agreements to suit your needs</li>
          <li>High-quality, well-maintained living spaces</li>
          <li>A balance of privacy and community</li>
          <li>Convenient all-inclusive packages</li>
        </ul>
        <p>
          Whether you're new to the city, looking to reduce your living costs, or simply prefer the 
          convenience of a fully-furnished room, we have options to suit your lifestyle and budget.
        </p>        
      </div>

      

      <div className="cta-section">
        <h2>Ready to Find Your New Home?</h2>
        <p>Contact us to discuss your specific requirements.</p>
        <Link to="/contact" className="cta-button">
          Get in touch!
        </Link>
      </div>
    </div>
  );
}

export default RoomsToRent;