import React from 'react';
import { Link } from 'react-router-dom';
import { Home, TrendingUp, Pocket, Shield, CheckCircle } from 'lucide-react';
import '../../css/content.css';

const FeatureCard = ({ icon, title, description }) => (
  <div className="service-card">
    {icon}
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

function RentToBuy() {
  return (
    <div className="rent-to-buy-page">
      <div className="hero">
        <h1>Your Path to Homeownership</h1>
        <p>Rent now, buy later. Our Rent to Buy scheme helps you step onto the property ladder.</p>
        <Link to="/contact" className="cta-button">
          Start Your Homeownership Journey
        </Link>
      </div>

      <div className="services-grid">
        <FeatureCard
          icon={<Home size={48} />}
          title="Live in Your Future Home"
          description="Move into your dream home today and work towards owning it. Enjoy the benefits of homeownership while you rent."
        />
        <FeatureCard
          icon={<TrendingUp size={48} />}
          title="Build Your Deposit"
          description="Gradually build equity, making homeownership more attainable than ever. With every payment, you're getting closer to owning the home you live in"
        />
        <FeatureCard
          icon={<Pocket size={48} />}
          title="Affordable Payments"
          description="Our Rent to Buy scheme offers competitive rental rates, making it easier for you to save for your deposit."
        />
        <FeatureCard
          icon={<Shield size={48} />}
          title="Secure Your Future"
          description="Lock in the future purchase price now, protecting you from rising property values while you prepare to buy."
        />
      </div>

      <div className="about-us">
        <h2>How Rent to Buy Works</h2>
        <p>
          Our Rent to Buy scheme is designed to help aspiring homeowners overcome the challenges of 
          saving for a deposit while paying rent. Here's how it works:
        </p>
        <ol>
          <li>Choose your ideal home from our selection of Rent to Buy properties.</li>
          <li>Provide us an upfront option fee to secure the property.</li>
          <li>Move in and start paying rent.</li>
          <li>Each month you will pay a top-up to the option fee to help build equity.</li>
          <li>Live in the property for an agreed period (typically 3-5 years).</li>
          <li>At the end of the term, should you choose to, your accumulated option fee will be credited to your deposit allowing you to purchase the property.</li>
        </ol>
        <p>
          This innovative approach allows you to settle into your new home immediately while working 
          towards full ownership, combining the flexibility of renting with the security of buying.
        </p>
        <Link to="/contact" className="cta-button">Get in Touch About Available Properties</Link>
      </div>

      <div className="solution-promise">
        <h2>Our Rent to Buy With Peace of Mind</h2>
        <div className="promise-content">
          <CheckCircle className="promise-icon" />
          <p>
            We're committed to helping you achieve your dream of homeownership. However, if your circumstances 
            change and you're unable to purchase the property at the end of the agreed term, we are happy 
            to work with you to find an alternative solution. You are not obligated to purchase the property.
            
          </p>
        </div>
        <p className="promise-cta">
          Your journey to homeownership is our priority. Let us guide you every step of the way.
        </p>
        <Link to="/contact" className="cta-button">Speak to a Rent to Buy Specialist</Link>
      </div>

      <div className="cta-section">
        <h2>Ready to Take Your First Step Towards Homeownership?</h2>
        <p>Contact us today to learn more about our Rent to Buy scheme and available properties.</p>
        <Link to="/contact" className="cta-button">
          Get Started Now
        </Link>
      </div>
    </div>
  );
}

export default RentToBuy;