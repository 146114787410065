import React from 'react';
import { Link } from 'react-router-dom';
import { Users, Building, TrendingUp, Shield, CheckCircle, Home } from 'lucide-react';
import '../../css/content.css';

const FeatureCard = ({ icon, title, description }) => (
  <div className="service-card">
    {icon}
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

function AboutUs() {
  return (
    <div className="about-us-page">
      <div className="hero">
        <h1>About Acre Sulis</h1>
        <p>Innovative property solutions tailored for landlords, tenants, and agents, bringing together the best of three worlds.</p>
        <Link to="/contact" className="cta-button">
          Find Out More
        </Link>
      </div>

      <div className="services-grid">
        <FeatureCard
          icon={<Users size={48} />}
          title="Lettings Mission"
          description="To revolutionise the letting experience for  and tenants and landlords alike. We do this by breaking the myth of rivalry and fostering trust and security."
        />
        <FeatureCard
          icon={<Building size={48} />}
          title="Sales Mission"
          description="To transform the security landscape for vendors by addressing their unique challenges and fostering innovative solutions that build trust and reliability"
        />
        <FeatureCard
          icon={<Home size={48} />}
          title="Landlords for Tenants"
          description="We strongly belive that those who want to own property should be able to do so, and those who want to rent should be able to do so. Our mission is provide for both groups."
        />
        <FeatureCard
          icon={<Shield size={48} />}
          title="Trusted Mission"
          description="Our commitment to transparency and reliability is at the core of everything we do. Solutions may not be simple but we will always be honest and upfront with our clients. With trust we can solve any problem."
        />
      </div>

      <div className="about-us">
  <section className="our-story">
    <h2>Our Story</h2>
    <p>
      Acre Sulis was founded with a vision to revolutionize the property market. We recognized the need for flexible, 
      innovative solutions that cater to the evolving needs of landlords, tenants, and agents alike. Our journey 
      began with a simple idea: to create a property services company that puts people first.
    </p>
    <p>
      Over the years, we've grown from a small startup to a comprehensive property solutions provider. Our success 
      is built on our commitment to understanding the unique challenges faced by our clients and developing tailored 
      strategies to meet their needs.
    </p>
    <p>
      Today, Acre Sulis is proud to offer a wide range of services, from traditional property management to innovative 
      rent-to-buy schemes and guaranteed rent options. Our team of experts brings together years of industry experience, 
      local market knowledge, and a passion for property to deliver exceptional results for our clients.
    </p>
  </section>

  <section className="our-name">
    <h2>The Story Behind Our Name</h2>
    <p>Acre Sulis is more than just a name; it's a reflection of our core values and mission in the property industry.</p>

    <div className="name-explanation">
      <h3>Acre</h3>
      <p>
        Derived from the Latin "ager," meaning "field" or "land," represents our deep connection to property and the earth itself. 
        It symbolizes the tangible assets we manage and the solid foundation we provide for our clients.
      </p>

      <h3>Sulis</h3>
      <p>
        Draws inspiration from Celtic mythology, where she was revered as a goddess of life, healing, and nourishment. 
        In ancient times, Sulis was associated with sacred springs, particularly in the city of Bath, where her waters 
        were believed to have restorative properties.
      </p>
    </div>

    <h3>Our Commitment</h3>
    <p>By combining these powerful concepts, <strong>Acre Sulis</strong> embodies our commitment to:</p>
    <ol>
      <li>Nurturing growth in the property market</li>
      <li>Fostering healing relationships between landlords and tenants</li>
      <li>Providing nourishing solutions that benefit all parties involved</li>
    </ol>

    <p>
      Just as Sulis' springs brought life and vitality to the land, we strive to bring prosperity and harmony to the property ecosystem. 
      Our name reflects our dedication to creating a balanced, thriving environment where property owners, tenants, and agents can all flourish.
    </p>
    <p>
      At Acre Sulis, we're not just managing properties; we're cultivating a new approach to property that honors the land 
      while nourishing the lives of those who dwell upon it.
    </p>
  </section>
</div>

      

      <div className="solution-promise">
        <h2>Our Commitment to You</h2>
        <div className="promise-content">
          <CheckCircle className="promise-icon" />
          <p>
            At Acre Sulis, we believe in going above and beyond for our clients. That's why we offer our unique Solution 
            Promise Guarantees. Whether you're a landlord looking for reliable tenants, a tenant searching for your dream home, 
            or a business seeking the perfect commercial space, we promise to find a solution that works for you. If we can't 
            directly assist you, we'll connect you with someone who can – ensuring you always have a path forward.
          </p>
        </div>
        <p className="promise-cta">
          Experience the Acre Sulis difference – where your property needs always come first.
        </p>
        <Link to="/contact" className="cta-button">Speak to Our Team</Link>
      </div>

      <div className="cta-section">
        <h2>Ready to Transform Your Property Experience?</h2>
        <p>Contact us today to discover how Acre Sulis can help you achieve your property goals.</p>
        <Link to="/contact" className="cta-button">
          Get Started Now
        </Link>
      </div>
    </div>
  );
}

export default AboutUs;