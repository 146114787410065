import React, { useState } from 'react';
import logo from '../images/logo.png';
import leftImage from '../images/left.png';
import rightImage from '../images/right.png';
import '../css/Header.css';

function Header({ onPageChange }) {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleNavClick = (page) => {
    onPageChange(page);
    setActiveDropdown(null);
    setMobileMenuOpen(false);
  };
  
  const handleMouseEnter = (dropdown) => {
    if (window.innerWidth > 768) {
      setActiveDropdown(dropdown);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 768) {
      setActiveDropdown(null);
    }
  };

  const toggleDropdown = (dropdown) => {
    if (window.innerWidth <= 768) {
      setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <header className="header">
      <img src={leftImage} alt="" className="header-decoration left" />
      <img src={rightImage} alt="" className="header-decoration right" />
      <div className="header-content">        
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="logo" />
          <div className="company-info">
    <span className="company-name">Acre Sulis</span>
    <span className="company-slogan">YOUR Property Solution</span>
          </div>
        </div>
        <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
          ☰
        </button>
        <nav className={`nav ${mobileMenuOpen ? 'nav-open' : ''}`}>
          <ul className="nav-links">
            <li><a onClick={() => handleNavClick('home')}>Home</a></li>
            <li 
              className="dropdown"
              onMouseEnter={() => handleMouseEnter('landlord')}
              onMouseLeave={handleMouseLeave}
            >
              <button onClick={() => toggleDropdown('landlord')}>Landlords</button>
              {activeDropdown === 'landlord' && (
                <ul className="dropdown-menu">
                  <li><a onClick={() => handleNavClick('landlords/management-services')}>Management Services</a></li>
                  <li><a onClick={() => handleNavClick('/landlords/guaranteed-rent')}>Guaranteed Rent</a></li>
                  <li><a onClick={() => handleNavClick('/landlords/profit-share-options')}>Profit Share Options</a></li>
                </ul>
              )}
            </li>
            <li 
              className="dropdown"
              onMouseEnter={() => handleMouseEnter('tenant')}
              onMouseLeave={handleMouseLeave}
            >
              <button onClick={() => toggleDropdown('tenant')}>Tenants</button>
              {activeDropdown === 'tenant' && (
                <ul className="dropdown-menu">
                  <li><a onClick={() => handleNavClick('/tenants/rent-to-buy')}>Rent to Buy</a></li>
                  <li><a onClick={() => handleNavClick('/tenants/rooms-to-rent')}>Rooms to Rent</a></li>
                  <li><a onClick={() => handleNavClick('/tenants/property-to-rent')}>Property to Rent</a></li>
                </ul>
              )}
            </li>
            <li><a onClick={() => handleNavClick('selling')}>Selling?</a></li>
            <li><a onClick={() => handleNavClick('/Agents')}>Agents</a></li>            
            <li><a onClick={() => handleNavClick('/about')}>About Us</a></li>
            <li><a onClick={() => handleNavClick('/contact')}>Contact Us</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;