import React from 'react';
import { Link } from 'react-router-dom';
import { Home, PoundSterling, Users, Wrench, CheckCircle } from 'lucide-react';
import '../../css/content.css';

const FeatureCard = ({ icon, title, description }) => (
  <div className="service-card">
    {icon}
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

function Management() {
  return (
    <div className="management-services-page">
      <div className="hero">
        <h1>Expert Rental Management Services</h1>
        <p>Specialized lettings focus at competitive rates - your property in safe hands</p>
        <Link to="/contact" className="cta-button">
          Get a Management Quote
        </Link>
      </div>

      <div className="services-grid">
        <FeatureCard
          icon={<PoundSterling size={48} />}
          title="Competitive Pricing"
          description="Enjoy professional management services at rates lower than industry standards, maximizing your rental income."
        />
        <FeatureCard
          icon={<Home size={48} />}
          title="Lettings Specialists"
          description="Our primary focus is lettings, ensuring your rental property receives dedicated, expert attention."
        />
        <FeatureCard
          icon={<Users size={48} />}
          title="Tenant Sourcing & Vetting"
          description="We find and thoroughly vet quality tenants, minimizing risks and void periods."
        />
        <FeatureCard
          icon={<Wrench size={48} />}
          title="Comprehensive Maintenance"
          description="Swift, cost-effective property maintenance to keep your investment in top condition."
        />
      </div>

      <div className="about-us">
        <h2>Why Choose Our Management Services?</h2>
        <p>
          At Acre Sulis, we specialize in rental property management, offering a service that's both comprehensive and cost-effective. Here's what sets us apart:
        </p>
        <ul>
          <li>Lower management fees compared to most other agencies</li>
          <li>Exclusive focus on lettings, ensuring specialized expertise</li>
          <li>Proactive property management to maximize your rental income</li>
          <li>Rigorous tenant screening process to secure reliable tenants</li>
          <li>Regular property inspections and maintenance oversight</li>
          <li>Transparent reporting and communication</li>
          <li>Compliance management to keep your property up to date with regulations</li>
        </ul>
        <p>
          Our lettings-focused approach means we understand the rental market inside out, allowing us to provide superior service at competitive rates.
        </p>
        <Link to="/contact" className="cta-button">Lets Talk</Link>
      </div>      

      <div className="cta-section">
        <h2>Ready for Hassle-Free Property Management?</h2>
        <p>Contact us today to discover how our specialized lettings management can benefit you.</p>
        <Link to="/contact" className="cta-button">
          Get Started Now
        </Link>
      </div>
    </div>
  );
}

export default Management;