import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import './css/App.css';
import DynamicContent from './components/DynamicContent';
import React, { useState, useEffect } from 'react';

// Import your new page components
import Home from './components/home/home';
import Management from './components/landlords/management';
import GuaranteedRent from './components/landlords/guaranteedRent';
import ProfitShareOptions from './components/landlords/profitShareOptions';
import RentToBuy from './components/tenants/rent2buy';
import RoomsToRent from './components/tenants/rooms2rent';
import PropertyToRent from './components/tenants/property2rent';
import Selling from './components/selling/selling';
import Agents from './components/agents/agents';
import Contact from './components/contact/contact';
import AboutUs from './components/aboutUs/aboutUs';
import FAQ from './components/FAQ/faq';

function AppContent() {
  const [currentPage, setCurrentPage] = useState('');
  const [isNavigatingViaState, setIsNavigatingViaState] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setIsNavigatingViaState(true);
    navigate(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (!isNavigatingViaState) {
      setCurrentPage(location.pathname.slice(1) || 'home');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    setIsNavigatingViaState(false);
  }, [location, isNavigatingViaState]);

  return (    
    <div className="App">      
      <Header onPageChange={handlePageChange} />
        <div className="transparent-layer">        
          <main className="main-content">
            <div className="stripe-top-right"></div>
            <div className="stripe-bottom-left"></div>
            <div className="container">
              
                {isNavigatingViaState ? (
                  <DynamicContent currentPage={currentPage} />
                ) : (
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/home" element={<Home />} />
                      <Route path="/landlords/management-services" element={<Management />} />
                      <Route path="/landlords/guaranteed-rent" element={<GuaranteedRent />} />
                      <Route path="/landlords/profit-share-options" element={<ProfitShareOptions />} />
                      <Route path="/tenants/rent-to-buy" element={<RentToBuy />} />
                      <Route path="/tenants/rooms-to-rent" element={<RoomsToRent />} />
                      <Route path="/tenants/property-to-rent" element={<PropertyToRent />} />
                      <Route path="/selling" element={<Selling />} />
                      <Route path="/agents" element={<Agents />} />                
                      <Route path="/about" element={<AboutUs />} />
                      <Route path="/contact" element={<Contact />} />
                      <Route path="/faq" element={<FAQ />} />               
                    </Routes>
                )}              
            </div>
          </main>       
        </div>      
      <Footer/>
    </div>    
  );
}


function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
