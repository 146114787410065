import React from 'react';
import { Link } from 'react-router-dom';
import { Home, Briefcase, DollarSign, TrendingUp, CheckCircle } from 'lucide-react';
import '../../css/content.css';

const FeatureCard = ({ icon, title, description }) => (
  <div className="service-card">
    {icon}
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

function Selling() {
  return (
    <div className="selling-page">
      <div className="hero">
        <h1>Unlock Your Property's Potential</h1>
        <p>Struggling to sell? We offer bespoke solutions to help you move forward.</p>
        <Link to="/contact" className="cta-button">
          Get Your Free Consultation
        </Link>
      </div>

      <div className="services-grid">
        <FeatureCard
          icon={<Briefcase size={48} />}
          title="Bespoke Selling Strategies"
          description="We craft unique strategies tailored to your property and situation, maximizing your chances of a successful sale."
        />
        <FeatureCard
          icon={<Home size={48} />}
          title="We Buy Your Property"
          description="Can't find a buyer? We can purchase your property directly, with our unique Above Market Offers."
        />
        <FeatureCard
          icon={<DollarSign size={48} />}
          title="Equity Release"
          description="Transform your property's value into cash flow while retaining ownership. Ideal for those seeking financial flexibility."
        />
        <FeatureCard
          icon={<TrendingUp size={48} />}
          title="Assisted Sales"
          description="Sell your property for more. We only get paid when we help you achieve a higher sale price."
        />
      </div>

      <div className="about-us">
        <h2>Why Choose Our Selling Solutions?</h2>
        <p>
          At Acre Sulis, we understand that every property and seller has unique
          circumstances. Whether you're facing challenges with a difficult-to-sell property,
          need to sell quickly, or are looking for creative ways to leverage your property's value,
          our team of experts is here to help. We combine market insight, innovative strategies,
          and a commitment to your success to deliver results when traditional methods fall short.
        </p>
        <Link to="/about" className="cta-button">Learn More About Our Approach</Link>
      </div>

      <div className="solution-promise">
        <h2>Our Seller's Guarantee</h2>
        <div className="promise-content">
          <CheckCircle className="promise-icon" />
          <p>
            We guarantee to make an offer on any property we view. 
            We have a whole host of solutions to help vendors sell their properties, either to us or through us. 
            Simply tell us your property problem and we will find you a solution. 
            Our offers are all based on a win-win principle that means your success is our success.
          </p>
        </div>
        <p className="promise-cta">
          Don't let your property sit on the market. Let us help you move forward.
        </p>
        <Link to="/contact" className="cta-button">Get Started Today</Link>
      </div>

      <div className="cta-section">
        <h2>Ready to Unlock Your Property's Potential?</h2>
        <p>Contact us for a free, no-obligation consultation and property assessment.</p>
        <Link to="/contact" className="cta-button">
          Schedule Your Consultation
        </Link>
      </div>
    </div>
  );
}

export default Selling;