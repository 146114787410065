import React from 'react';
import { Link } from 'react-router-dom';
import { Home, Search, Clipboard, Key, CheckCircle } from 'lucide-react';
import '../../css/content.css';

const FeatureCard = ({ icon, title, description }) => (
  <div className="service-card">
    {icon}
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

function PropertyToRent() {
  return (
    <div className="property-to-rent-page">
      <div className="hero">
        <h1>Find Your Perfect Rental Home</h1>
        <p>Discover a wide range of quality properties to suit your lifestyle and budget</p>
        <Link to="/contact" className="cta-button">
          Start Your Search
        </Link>
      </div>

      <div className="services-grid">
        <FeatureCard
          icon={<Home size={48} />}
          title="Diverse Property Options"
          description="From cozy apartments to spacious family homes, we offer a variety of rental properties to meet your needs."
        />
        <FeatureCard
          icon={<Search size={48} />}
          title="Tailored Search"
          description="Use our industry knowledge to find perfect properties that match your specific requirements and preferences."
        />
        <FeatureCard
          icon={<Clipboard size={48} />}
          title="Transparent Process"
          description="Clear documentation and straightforward rental agreements ensure a smooth and worry-free renting experience."
        />
        <FeatureCard
          icon={<Key size={48} />}
          title="Flexible Tenancies"
          description="Whether you need a short-term let or a long-term home, we have options to suit your timeline."
        />
      </div>

      <div className="about-us">
        <h2>Why Rent with Us?</h2>
        <p>
          At Bespoke Property Solutions, we're committed to providing an exceptional rental experience. 
          When you choose to rent a property through us, you benefit from:
        </p>
        <ul>
          <li>A wide selection of high-quality, well-maintained properties</li>
          <li>Competitive rental rates and transparent fee structures</li>
          <li>Professional property management and responsive maintenance services</li>
          <li>Thorough vetting process to ensure responsible tenants and landlords</li>
          <li>Support throughout your tenancy, from move-in to move-out</li>
        </ul>
        <p>
          Whether you're a young professional, a growing family, or anyone in between, we have rental 
          options to suit your lifestyle and budget.
        </p>
        
      </div>

      

      <div className="cta-section">
        <h2>Ready to Find Your New Home?</h2>
        <p>Let us help you find the perfect match for your needs.</p>
        <Link to="/contact" className="cta-button">
          Get in touch!
        </Link>
      </div>
    </div>
  );
}

export default PropertyToRent;