import React from 'react';
import { Link } from 'react-router-dom';
import { Home, Users, DollarSign, Building, Phone, CheckCircle } from 'lucide-react';
import '../../css/content.css';

const HomePage = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero">
        <h1>Bespoke Property Solutions</h1>
        <p>Bespoke strategies for landlords, tenants, and property vendors</p>
        <Link to="/contact" className="cta-button">
          Get Started
        </Link>
      </div>

      {/* Services Section */}
      <div className="services-grid">
        <ServiceCard
          icon={<Users />}
          title="For Landlords"
          description="Management services, guaranteed rent, and profit-share options."
          link="/landlords/management-services"
        />
        <ServiceCard
          icon={<Home />}
          title="For Tenants"
          description="Find your perfect home or get on the property ladder with our rent-to-buy schemes!"
          link="/tenants/rent-to-buy"
        />
        <ServiceCard
          icon={<DollarSign />}
          title="For Sellers"
          description="Maximize your property's value with our unique selling strategies."
          link="/selling"
        />
        <ServiceCard
            icon={<Building size={24} />}
            title="For Estate Agents"
            description="Partner with us to double your commission and access exclusive property deals for your clients."
            link="/agents"
          />
      </div>

      {/* About Us Section */}
      <div className="about-us">
        <h2>Why Choose Us?</h2>
        <p>
          With years of experience in the property market, we specialize in creating innovative solutions 
          for all your property needs. Our team is dedicated to providing personalized service 
          and achieving the best outcomes for our clients.
        </p>
        <Link to="/about">Learn more about our approach</Link>
      </div>

      {/* Solution Promise Guarantee Section */}
      <div className="solution-promise">
        <h2>Our Solution Promise Guarantee</h2>
        <div className="promise-content">
          <CheckCircle className="promise-icon" />
          <p>
            Our extensive network of industry contacts means we're confident we can help with any property-related challenge. 
            In the rare event we can't directly assist you, we promise to connect you with someone who can. 
            No matter your property needs, we're here to guide you towards the right solution.
          </p>
        </div>
        <p className="promise-cta">
          Don't hesitate to reach out – we're ready to help with any property question or challenge you face.
        </p>
        <Link to="/contact" className="cta-button">Contact Us Today</Link>
      </div>

      {/* Call to Action */}
      <div className="cta-section">
        <h2>Ready to solve your property challenges?</h2>
        <p>Contact us today for a free consultation</p>
        <Link to="/contact" className="cta-button">
          Get in Touch
        </Link>
      </div>
    </div>
  );
};

const ServiceCard = ({ icon, title, description, link }) => (
  <div className="service-card">
    {icon}
    <h3>{title}</h3>
    <p>{description}</p>
    <Link to={link}>Learn More</Link>
  </div>
);

export default HomePage;