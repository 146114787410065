import React from 'react';
import { Link } from 'react-router-dom';
import { ShieldCheck, Banknote, Calendar, BarChart, CheckCircle } from 'lucide-react';
import '../../css/content.css';

const FeatureCard = ({ icon, title, description }) => (
  <div className="service-card">
    {icon}
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

function GuaranteedRent() {
  return (
    <div className="guaranteed-rent-page">
      <div className="hero">
        <h1>Guaranteed Rent for Landlords</h1>
        <p>Secure your rental income for 3-5 years with zero void periods. 
          We offer landlords a stress-free and rewarding rental experience, you can relax knowing your rental success is our top priority.</p>
        <Link to="/contact" className="cta-button">
          Get Your Guaranteed Rent Quote
        </Link>
      </div>

      <div className="services-grid">
        <FeatureCard
          icon={<ShieldCheck size={48} />}
          title="Guaranteed Income"
          description="Receive your rent on time, every time, regardless of tenant occupancy or payment issues."
        />
        <FeatureCard
          icon={<Banknote size={48} />}
          title="No Void Periods"
          description="We cover the rent even when the property is empty, ensuring continuous income for 3-5 years."
        />
        <FeatureCard
          icon={<Calendar size={48} />}
          title="Long-Term Security"
          description="Lock in a stable rental income for up to 5 years, protecting you from market fluctuations."
        />
        <FeatureCard
          icon={<BarChart size={48} />}
          title="Hassle-Free Management"
          description="We handle all aspects of property management, from finding tenants to maintenance."
        />
      </div>

      <div className="about-us">
        <h2>How Our Guaranteed Rent Scheme Works</h2>
        <p>
          Our Guaranteed Rent service offers landlords a stress-free way to earn a stable income from their properties. Here's how it works:
        </p>
        <ol>
          <li>We lease your property for a fixed term of 3-5 years.</li>
          <li>We guarantee your rent for the entire period, paid monthly in advance.</li>
          <li>We take on all responsibilities of finding and managing tenants.</li>
          <li>You receive your rent even during void periods or if tenants fail to pay.</li>
          <li>We handle all maintenance, keeping your property in excellent condition.</li>
        </ol>
        <p>
          This scheme provides you with a predictable, hassle-free income stream while we take care of all the day-to-day responsibilities of property management.
        </p>
        <Link to="/faq" className="cta-button">Read Our Landlord FAQs</Link>
      </div>

      

      <div className="cta-section">
        <h2>Ready to Secure Your Rental Income?</h2>
        <p>Contact us today to learn more about our Guaranteed Rent scheme and get a personalized quote for your property.</p>
        <Link to="/contact" className="cta-button">
          Get Started Now
        </Link>
      </div>
    </div>
  );
}

export default GuaranteedRent;