import React from 'react';
import Home from './home/home';
import Management from './landlords/management';
import GuaranteedRent from './landlords/guaranteedRent';
import ProfitShareOptions from './landlords/profitShareOptions';
import RentToBuy from './tenants/rent2buy';
import RoomsToRent from './tenants/rooms2rent';
import PropertyToRent from './tenants/property2rent';
import Selling from './selling/selling';
import Agents from './agents/agents';
import Contact from './contact/contact';
import AboutUs from './aboutUs/aboutUs';
import FAQ from './FAQ/faq';

const DynamicContent = ({ currentPage }) => {
  switch (currentPage) {
    case 'home':
      return <Home />;
    case 'landlords/management-services':
      return <Management />;
    case 'landlords/guaranteed-rent':
      return <GuaranteedRent />;
    case 'landlords/profit-share-options':
      return <ProfitShareOptions />;
    case 'tenants/rent-to-buy':
      return <RentToBuy />;
    case 'tenants/rooms-to-rent':
      return <RoomsToRent />;
    case 'tenants/property-to-rent':
      return <PropertyToRent />;
    case 'selling':
      return <Selling />;
    case 'agents':
      return <Agents />;
    case 'about':
      return <AboutUs />;
    case 'contact':
      return <Contact />;
    case 'faq':
      return <FAQ />;
    default:
      return <Home />;
  }
};

export default DynamicContent;