import React from 'react';
import { Phone, Mail, Clock, MapPin } from 'lucide-react';
import '../../css/content.css';

const ContactMethod = ({ icon, title, content, link, linkText }) => (
  <div className="service-card">
    {icon}
    <h3>{title}</h3>
    <p>{content}</p>
    {link && <a href={link} className="cta-button">{linkText}</a>}
  </div>
);

function Contact() {
  return (
    <div className="contact-page">
      <div className="hero">
        <h1>Get in Touch</h1>
        <p>We're here to help with all your property needs. Reach out to us today!</p>
      </div>

      <div className="services-grid">
        <ContactMethod
          icon={<Phone size={48} />}
          title="Call Us"
          content="Speak directly with our property experts. We're available Monday to Friday, 9am to 5pm."
          link="tel:+447707982366"
          linkText="Call +44 7707 982 366"
        />
        <ContactMethod
          icon={<Mail size={48} />}
          title="Email Us"
          content="Send us an email anytime. We aim to respond to all inquiries within 48 hours."
          link="mailto:info@acresulis.co.uk"
          linkText="Email Us"
        />
        <ContactMethod
          icon={<Clock size={48} />}
          title="Our Hours"
          content="Monday to Friday: 9am - 5pm
          Saturday: 10am - 2pm
          Sunday: Closed"
        />
        <ContactMethod
          icon={<MapPin size={48} />}
          title="Our Location"
          content="Biggleswade, Bedfordshire, UK"
        />
      </div>

      <div className="about-us">
        <h2>Why Reach Out?</h2>
        <p>
          Whether you're a landlord looking for management services, a tenant searching for the perfect home, 
          a property owner considering selling, or an agent helping your clients,
          our team of experts is ready to assist you. We pride ourselves on our personalized approach and
          innovative solutions tailored to your unique needs.
        </p>
      </div>

      <div className="solution-promise">
        <h2>Our Communication Promise</h2>
        <div className="promise-content">
          <Phone className="promise-icon" />
          <p>
            We understand the importance of timely communication in the property business. That's why we
            guarantee to respond to all inquiries within 48 hours during business days.
          </p>
        </div>
      </div>

      <div className="cta-section">
        <h2>Ready to Take the Next Step?</h2>
        <p>Don't hesitate to reach out. We're excited to hear from you and discuss how we can help with your property needs.</p>
        <a href="tel:+447507494884" className="cta-button">
          Call Us Now
        </a>
      </div>
    </div>
  );
}

export default Contact;